.page-header {
  background: #0080bc;
  margin: 0 auto;
  min-width: 993px;
  padding-top: 48px;
  position: relative;
  width: 100%;
}
.page-header > .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header .tx-solr {
  width: 260px;
}
.page-header .search {
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.page-header .search > input {
  font-family: 'Din Pro', sans-serif;
  padding: 5px 26px 5px 7px;
  box-sizing: border-box;
  color: #0080bc;
}
.page-logo a,
.page-logo img {
  display: block;
}
.page-title {
  color: #454661;
  font-size: 23px;
  line-height: 28px;
  text-transform: uppercase;
  padding-bottom: 26px;
  border-bottom: 2px solid #B7D2E8;
  margin-bottom: 26px;
}
.page-landingpage--titel {
  color: #454661;
  font-size: 23px;
  line-height: 28px;
}
.page-landingpage--landingpage-titel {
  color: #1968a8;
  font-size: 23px;
  font-weight: bold;
  line-height: 28px;
  text-transform: uppercase;
  padding-bottom: 5px;
}
@media screen and (max-width: 1130px) {
  .page-header {
    height: 60px;
    left: 0;
    padding-top: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .page-header .wrapper {
    height: 100%;
  }
  .page-header .page-logo,
  .page-header .page-logo img {
    height: 55px;
    width: auto;
  }
  .page-header .page-logo a {
    display: inline-block;
    vertical-align: top;
  }
  .page-header .page-logo img {
    margin-top: 0;
  }
  .page-header.js-offcanvas nav {
    transform: translateX(0);
  }
  .page-header .search {
    position: relative;
    right: auto;
    top: auto;
  }
  .page-header .tx-solr {
    background: #d8ecfe;
    opacity: 0;
    padding: 15px;
    position: fixed;
    right: 60px;
    top: 60px;
    transition: 200ms;
    visibility: hidden;
  }
  .page-header .tx-solr-q {
    appearance: none;
  }
  .page-header.js-search .tx-solr {
    opacity: 1;
    visibility: visible;
  }
  .js-offcanvas:before {
    background: rgba(131, 176, 212, 0.5);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 998;
  }
  .js-offcanvas-toggle {
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMThweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTggMTQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE4IDE0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDBoMTh2MkgwVjB6Ii8+DQoJPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTAsNmgxOHYySDBWNnoiLz4NCgk8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMCwxMmgxOHYySDBWMTJ6Ii8+DQo8L2c+DQo8dGV4dCB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAxIDI5Ny42NDA2IDQyMC45NDUzKSIgZm9udC1mYW1pbHk9IidNeXJpYWRQcm8tUmVndWxhciciIGZvbnQtc2l6ZT0iMTIiPkBpY28tY2xvc2U8L3RleHQ+DQo8L3N2Zz4NCg==") no-repeat 50%;
    cursor: pointer;
    display: block;
    height: 60px;
    position: fixed;
    right: 0;
    top: 0;
    width: 60px;
    z-index: 1000;
  }
  .js-offcanvas-toggle:before {
    display: none;
  }
  .js-offcanvas .js-offcanvas-toggle {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTQuMTQ0cHgiIGhlaWdodD0iMTQuMTQzcHgiIHZpZXdCb3g9IjE4LjYzOCAyMS41OTYgMTQuMTQ0IDE0LjE0MyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxOC42MzggMjEuNTk2IDE0LjE0NCAxNC4xNDMiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHJlY3QgeD0iMTYuNzA5IiB5PSIyNy42NjYiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgLTEyLjc0MDEgMjYuNTc0OCkiIGZpbGw9IiMwMDU4OUYiIHdpZHRoPSIxOC4wMDEiIGhlaWdodD0iMi4wMDEiLz4NCjxyZWN0IHg9IjE2LjcwOSIgeT0iMjcuNjY3IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgMC43MDcxIC0wLjcwNzEgMC43MDcxIDI3LjggLTkuNzgzKSIgZmlsbD0iIzAwNTg5RiIgd2lkdGg9IjE4IiBoZWlnaHQ9IjIuMDAxIi8+DQo8L3N2Zz4NCg==");
  }
  .js-search-toggle {
    background: center no-repeat transparent;
    border-radius: 0;
    cursor: pointer;
    display: block;
    height: 60px;
    position: fixed;
    right: 60px;
    top: 0;
    transition: 200ms;
    width: 60px;
  }
  .js-search-toggle:before {
    transition: 200ms;
    color: #ffffff;
    content: 's';
    display: inline;
    font-size: 15px;
    margin-right: 0;
    font-family: 'Iconfont';
  }
  .js-search .js-search-toggle {
    background-color: #d8ecfe;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTQuMTQ0cHgiIGhlaWdodD0iMTQuMTQzcHgiIHZpZXdCb3g9IjE4LjYzOCAyMS41OTYgMTQuMTQ0IDE0LjE0MyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxOC42MzggMjEuNTk2IDE0LjE0NCAxNC4xNDMiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHJlY3QgeD0iMTYuNzA5IiB5PSIyNy42NjYiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzA3MSAtMC43MDcxIDAuNzA3MSAwLjcwNzEgLTEyLjc0MDEgMjYuNTc0OCkiIGZpbGw9IiMwMDU4OUYiIHdpZHRoPSIxOC4wMDEiIGhlaWdodD0iMi4wMDEiLz4NCjxyZWN0IHg9IjE2LjcwOSIgeT0iMjcuNjY3IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgMC43MDcxIC0wLjcwNzEgMC43MDcxIDI3LjggLTkuNzgzKSIgZmlsbD0iIzAwNTg5RiIgd2lkdGg9IjE4IiBoZWlnaHQ9IjIuMDAxIi8+DQo8L3N2Zz4NCg==");
  }
  .js-search .js-search-toggle:before {
    content: '';
  }
}
