@import '_globals.less';

@userpanelHeight: 48px;
@headerSearchWidth: 260px;

// HEADER
// ============================

.page-header {
    background: @c-primary-1;
    margin: 0 auto;
    min-width: 993px;
    padding-top: @userpanelHeight;
    position: relative;
    width: 100%;

    > .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tx-solr {
        width: @headerSearchWidth;
    }

    .search {
        border-radius: 2px;
        box-sizing: border-box;
        position: relative;
        width: 100%;

        > input {
            font-family: 'Din Pro', sans-serif;
            padding: 5px 26px 5px 7px;
            box-sizing: border-box;
            color: @c-blue-200;
        }
    }
}

.page-logo {
    a, img {
        display: block;
    }
}

.page-title {
    color: #454661;
    font-size: 23px;
    line-height: 28px;
    text-transform: uppercase;
    padding-bottom: 26px;
    border-bottom: 2px solid @c-lightblue-250;
    margin-bottom: 26px;
}

.page-landingpage--titel {
    color: #454661;
    font-size: 23px;
    line-height: 28px;
}

.page-landingpage--landingpage-titel {
    color: @c-blue-100;
    font-size: 23px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
    padding-bottom: 5px;
}

// TODO: migrate more layout relevant styles from Screen.less

@media screen and (max-width: 1130px) {
    .page-header {
        height: @stickyHeaderHeight;
        left: 0;
        padding-top: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;

        .wrapper {
            height: 100%;
        }

        .page-logo,
        .page-logo img {
            height: 55px;
            width: auto;
        }

        .page-logo {
            a {
                display: inline-block;
                vertical-align: top;
            }

            img {
                margin-top: 0;
            }
        }

        &.js-offcanvas {
            nav {
                transform: translateX(0);
            }
        }

        .search {
            position: relative;
            right: auto;
            top: auto;
        }

        .tx-solr {
            background: @c-lightblue-400;
            opacity: 0;
            padding: 15px;
            position: fixed;
            right: 60px;
            top: @stickyHeaderHeight;
            transition: 200ms;
            visibility: hidden;
        }

        .tx-solr-q {
            appearance: none;
        }

        &.js-search {
            .tx-solr {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .js-offcanvas {
        &:before {
            background: fadeOut(@c-primary-2, 50%);
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: @offCanvasZIndex - 1;
        }
    }

    .js-offcanvas-toggle {
        background: url(@ico-hamburger) no-repeat 50%;
        cursor: pointer;
        display: block;
        height: 60px;
        position: fixed;
        right: 0;
        top: 0;
        width: 60px;
        z-index: @offCanvasZIndex + 1;

        &:before {
            display: none;
        }

        .js-offcanvas & {
            background-image: url(@ico-close);
        }
    }

    .js-search-toggle {
        background: center no-repeat transparent;
        border-radius: 0;
        cursor: pointer;
        display: block;
        height: 60px;
        position: fixed;
        right: 60px;
        top: 0;
        transition: 200ms;
        width: 60px;

        &:before {
            transition: 200ms;
            color: @c-white;
            content: 's';
            display: inline;
            font-size: 15px;
            margin-right: 0;
            font-family: 'Iconfont';
        }

        .js-search & {
            background-color: @c-lightblue-400;
            background-image: url(@ico-close);

            &:before {
                content: '';
            }
        }
    }
}